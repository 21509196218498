<template>
	<div class="identification">
		<div class="identification__column identification__column--first">
			<h2 class="identification__title">Подтверждение личности</h2>

			<h3 class="identification__subtitle">Для чего нам ваши документы?</h3>

			<div class="identification__description">
				<p>
					Документы подтверждают, что вы тот, за кого себя выдаете. С их помощью
					мы отсеиваем мошенников, повышаем уровень безопасностии ограничиваем
					доступ несовершеннолетним пользователям. Некоторые варианты жилья
					доступны для бронирования только тем, кто подтвердил удостоверение
					личности.
				</p>

				<p>
					Мы никогда не передаем полученную личную информацию
					хозяевам/организаторамили другим пользователям Теремка. В рамках
					действующего законодательства мы можем предоставить данные
					удостоверения личности банкам и другим финансовым организациям (для
					исполнения законов в сфере налогообложения, борьбы с отмыванием денеги
					соблюдения санкционных мер),а также органам правопорядка, если им
					требуется помощь Теремка в расследовании.
				</p>

				<p>
					Мы просим всех подтверждать свою личность. Этот этап помогает укрепить
					доверие, повысить безопасность, а также сократить масштабы
					онлайн-мошенничества. Информация, которую Вы предоставляете, защищена
					нашей Политикой конфиденциальности.
				</p>

        <p>
          Размер фото должен не превышать 10 мб
        </p>
			</div>

			<div class="identification__buttons">
				<tk-button font-size="15px" :height="40" @click="type = keys.PASSPORT">
          Паспорт
        </tk-button>
				<tk-button font-size="12px" :height="40" @click="type = keys.LICENSE">
					Водительское удостоверение
				</tk-button>
			</div>

			<div class="identification__privacy">
				Мы просим всех подтверждать свою личность. Этот этап помогает укрепить
				доверие, повысить безопасность, а также сократить масштабы
				онлайн-мошенничества. Информация, которую вы предоставляете, защищенa
				нашей
				<router-link to="/privacy" class="policy">
					Политикой конфиденциальности
				</router-link>
			</div>
		</div>

		<transition name="slide-fade">
			<div
				v-if="type"
				class="identification__column identification__column--second"
			>
				<div class="identification__upload-wrapper">
          <h3 class="identification__subtitle">Фото документа</h3>
          
					<div class="identification__document-upload" :style="imageSettings">
						<label for="upload-avatar">
							<img
								v-if="imageDocument"
								:src="imageDocument"
								:style="imageSettings"
								:alt="$t('images.alts.user.photo')"
							/>

							<input
								@change="changeFileDocument"
								form="profile-form"
								type="file"
								id="upload-avatar"
								accept=".jpg, .png, .jpeg"
							/>
						</label>
					</div>
				</div>

				<tk-button
					:height="40"
					class="identification__document-button"
          :disabled="inProcess"
					@click="upload"
				>
					Загрузить фото документа
				</tk-button>

				<div v-if="!isDocumentUpload" class="identification__warning">
					Мы просим Вас сделать снимок лица, чтобы сравнить его с фото в
					загруженном удостоверении личности и убедиться, что это действительно
					Вы. Мы никогда не передаем полученную личную информацию
					хозяевам/организаторам или другим пользователям Теремка.
				</div>

				<div v-if="isDocumentUpload" class="identification__upload-wrapper">
          <h3 class="identification__subtitle">Фото лица</h3>

					<div class="identification__photo">
						<label>
							<img
								v-if="userPhoto"
								:src="userPhoto"
								height="200"
								width="200"
								:alt="$t('images.alts.user.photo')"
								class="image-comp"
							/>

							<input
								@change="changeUserPhoto"
								type="file"
								id="upload-photo-user"
								accept=".jpg, .png, .jpeg"
							/>
						</label>
					</div>

					<tk-button
						:height="40"
						font-size="15px"
						line-height="100%"
						class="identification__accept-button"
						kind="main-brown"
            :disabled="inProcess"
						@click="submit"
					>
						Подтвердить
					</tk-button>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import modals from '@/constants/modals'
import sizes from "@/constants/sizes";

const keys = {
	PASSPORT: 'PASSPORT',
	LICENSE: 'DRIVER_LICENSE'
}

export default {
	watch: {
		type: function () {
			this.imageDocument = ''
			this.fileDocument = ''

			this.userPhoto = ''
			this.fileUserDocument = ''
		}
	},
  inject: ["metricsService"],
	data() {
		return {
			type: '', // PASSPORT or DRIVER_LICENSE
			isDocumentUpload: '',

			fileDocument: '',
			imageDocument: '',

			userPhoto: '',
			fileUserDocument: '',
      inProcess: false
		}
	},
	computed: {
		keys() {
			return keys
		},
		imageSettings() {
			return this.type === this.keys.PASSPORT
				? {
						height: '240px',
						width: '200px'
				  }
				: { height: '120px', width: '200px' }
		},
    maxSize() {
      return sizes.megabyte * 10
    }
	},
	methods: {
		successIdentPhoto() {
			this.$store.commit('showModal', {
				name: modals.SUCCESS_IDENTIFICATION_PHOTO,
				props: null,
				isShow: true
			})
		},
		changeFileDocument({ target }) {
			if (target.files[0]) {
        if (target.files[0].size > this.maxSize) {
          this.$store.commit('showNotification', {
            type: 'error',
            text: 'Размер фото должен не превышать 10 мб'
          })
          return
        }
				this.imageDocument = URL.createObjectURL(target.files[0])
				this.fileDocument = target.files[0]
			}
		},
		changeUserPhoto({ target }) {
			if (target.files[0]) {
        if (target.files[0].size > this.maxSize) {
          this.$store.commit('showNotification', {
            type: 'error',
            text: 'Размер фото должен не превышать 10 мб'
          })
          return
        }
				this.userPhoto = URL.createObjectURL(target.files[0])
				this.fileUserDocument = target.files[0]
			}
		},
		async upload() {
      this.inProcess = true
			if (!this.fileDocument) {
				this.$store.commit('showNotification', {
					type: 'info',
					text: 'Сначала выберите фото документа, кликнув на серую область.'
				})
        this.inProcess = false
				return
			}

			const data = new FormData()
			data.append('upload[0]', this.fileDocument)
			data.append('type', this.type)

			const response = await this.$api.user.uploadDoc(data)

			if (response && (response.error || response.errors)) {
        this.$store.commit('showNotification', {
          type: 'error',
          text: response.errors?.upload ?? 'Размер фото должен не превышать 10 мб'
        })
        this.inProcess = false
				return
			}

			this.isDocumentUpload = true
			this.$store.commit('showNotification', {
				type: 'success',
				text: 'Фото документа успешно добавлено'
			})
      this.inProcess = false
		},
		async submit() {
      this.inProcess = true
			if (!this.fileUserDocument) {
				this.$store.commit('showNotification', {
					type: 'info',
					text: 'Сначала выберите фото лица, кликнув на серую область.'
				})
        this.inProcess = false
				return
			}

			const data = new FormData()
			data.append('upload[0]', this.fileUserDocument)

			const response = await this.$api.user.uploadUserPhoto(data)

			if (response && (response.error || response.errors)) {
        this.$store.commit('showNotification', {
          type: 'error',
          text: response.errors?.upload ?? 'Размер фото должен не превышать 10 мб'
        })
        this.inProcess = false
				return
			}

			this.successIdentPhoto()
      this.inProcess = false
      this.metricsService.sendEvent("verified")
		}
	}
}
</script>

<style lang="sass">
.identification
  position: relative

  display: flex
  justify-content: space-between

  color: $black-secondary

  &__column
    width: 420px

    &--first
      margin-right: 20px

    &--second
      display: flex
      flex-wrap: wrap
      justify-content: flex-start
      align-content: flex-start

  &__title
    display: flex
    align-items: center
    margin-bottom: 30px

    font-weight: bold
    font-size: 24px
    line-height: 24px

  &__subtitle
    flex-basis: 100%
    width: 100%
    text-align: center
    margin-bottom: 15px

    font-weight: 600
    font-size: 18px
    line-height: 18px

  &__description
    padding-right: 25px
    margin-bottom: 15px

    font-size: 14px
    line-height: 16px

  &__buttons
    display: flex
    justify-content: space-between
    margin-bottom: 30px

    button
      width: 200px
      height: 40px

      &:first-child
        margin-right: 20px

  &__privacy
    font-size: 16px
    line-height: 18px

    .policy
      color: $green
      text-decoration: underline

      transition: 0.1s linear

      &:hover
        color: $brown

  &__upload-wrapper
    display: flex
    flex-wrap: wrap
    justify-content: center
    max-width: 240px
    flex-basis: 100%
    margin-bottom: 45px

  &__document-upload
    position: relative

    display: flex
    align-items: center
    justify-content: center

    box-sizing: border-box
    background-color: #CBCBCB
    border-radius: 12px

    overflow: hidden

    &:before
      content: ''

      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0

      width: 45px
      height: 40px
      margin: auto

      background-image: url('~@/assets/img/svg/camera-white.svg')
      background-repeat: no-repeat
      background-position: center
      background-size: 45px 40px
      z-index: 1

    label
      position: relative
      display: flex
      justify-content: center
      align-items: center
      width: 100%
      height: 100%
      z-index: 5

      img
        position: relative

        object-fit: cover
        z-index: 2

      input
        display: none

  &__document-button
    width: 240px
    height: 40px
    margin-bottom: 30px

  &__warning
    padding-right: 15px

    font-size: 16px
    line-height: 20px

  &__photo
    position: relative

    display: flex
    justify-content: center
    flex-basis: 100%
    margin-top: 20px
    margin-bottom: 30px

    overflow: hidden

    label
      position: relative

      display: block
      width: 200px
      height: 200px

      background-color: #CBCBCB
      border-radius: 100%

      z-index: 3

      &:before
        content: ''

        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0

        width: 45px
        height: 40px
        margin: auto

        background-image: url('~@/assets/img/svg/camera-white.svg')
        background-repeat: no-repeat
        background-position: center
        background-size: 45px 40px

      input
        display: none

      img
        position: relative

        border-radius: 50%
        border: 1px solid $green

        object-fit: cover
        z-index: 2

  &__accept-button
    width: 140px
    height: 40px
    font-style: normal
    font-weight: 700
    font-size: 15px
    line-height: 100%

  // Animation
  .slide-fade-enter-active
    transition: all 0.3s ease-out

  .slide-fade-leave-active
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1)

  .slide-fade-enter-from,
  .slide-fade-leave-to
    transform: translateX(20px)
    opacity: 0

// 1080
@media screen and (max-width: 1440px)
  .identification
    &__column
      width: 384px

    &__description
      padding-right: 0

    &__buttons
      button
        width: 180px
        padding: 0 5px

// 996
@media screen and (max-width: 1180px)
  .identification
    &__column
      width: 352px

// 900
@media screen and (max-width: 1024px)
  .identification
    &__column
      width: 314px

// 768
@media screen and (max-width: 912px)
  .identification
    &__column
      width: 258px

// 540
@media screen and (max-width: 800px)
  .identification
    flex-wrap: wrap

    &__column
      width: 100%

      &--first
        margin-bottom: 30px

    &__buttons
      flex-direction: column

      button
        margin-bottom: 15px
</style>
